import "/src/assets/css/main.css"
import ElementPlus from "/node_modules/.vite/deps/element-plus.js?v=9c826a96"
import "/node_modules/element-plus/dist/index.css"
import VueMask from "/node_modules/.vite/deps/@devindex_vue-mask.js?v=5ade0a09";

import { createApp } from "/node_modules/.vite/deps/vue.js?v=b0216e70"
import { createPinia } from "/node_modules/.vite/deps/pinia.js?v=3ac39600"
import i18n from "/src/i18n/index.ts"
import App from "/src/App.vue"
import router from "/src/router/index.js"
import VueApexCharts from "/node_modules/.vite/deps/vue3-apexcharts.js?v=8e838d4f";

const app = createApp(App)

app.use(createPinia())
app.use(router).use(ElementPlus).use(VueMask).use(i18n).use(VueApexCharts)

app.mount('#app')
